<!--
    @name: index
    @description：index
    @author: ZengWei
    @date: 2021-12-16 17:05
-->
<template>
	<div class="form-data-list" v-loading="loading">
		<div class="header">
			<h5 class="form-title" @click="handleBack">
        <el-button class="back-btn"  type="text">
          <i style="font-size: 14px" class="iconfont iconc-chevron-left" />
        </el-button>
        {{moduleName}}
      </h5>
			<div class="action-bar">
				<el-tooltip
					class="item"
					effect="dark"
					content="刷新"
					placement="bottom"
				>
					<i class="el-icon-refresh" @click="searchRest"></i>
				</el-tooltip>
				<el-tooltip
					class="item"
					effect="dark"
					:content="showSearch?'收起搜索':'展开搜索'"
					placement="bottom"
				>
					<i
						v-if="showSearch"
						class="el-icon-arrow-down"
						@click="showSearch=false"
					></i>
					<i
						v-else
						class="el-icon-arrow-up"
						@click="showSearch=true"
					></i>
				</el-tooltip>
				<el-tooltip v-if="listSheetUuid" class="item" effect="dark" content="数据导出" placement="bottom" >
					<i class="el-icon-position" @click="handleListExport"></i>
				</el-tooltip>
        <!-- <el-tooltip class="item" effect="dark" content="数据查询器" placement="bottom">
					<i class="el-icon-search"></i>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="数据模板" placement="bottom">
					<i class="el-icon-receiving"></i>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="批量删除" placement="bottom">
					<i class="el-icon-delete"></i>
				</el-tooltip>-->
				<el-button
					type="primary"
					size="small"
					icon="el-icon-plus"
					style="margin-left: 10px"
					@click="openAddModal"
				>新增
				</el-button>
			</div>
		</div>
		<template v-if="searchComp && searchComp.length">
      <div class="search-container">
        <div
          v-show="showSearch"
          class="search"
        >
          <div class="search-comp">
            <el-form
              label-position="top"
              size="small"
              label-width="80px"
            >
              <el-row :gutter="20">
                <el-col
                  :span="6"
                  v-for="(item,index) in searchComp"
                  :key="item.config.__config__.formId"
                >
                  <el-form-item :label="item.config.__config__.label">
                    <input-item :element="item" :key="index"></input-item>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="search-btn">
            <el-button
              size="small"
              @click="searchRest"
            >重置</el-button>
            <el-button
              size="small"
              type="primary"
              @click="searchAction"
            >查询</el-button>
          </div>
        </div>
      </div>
		</template>

		<div ref="tableWrapper" class="table-list">
			<el-table
				:data="tableData"
				border
				height="100%"
				style="width: 100%;"
			>
				<el-table-column align="center" type="selection" width="55"></el-table-column>

				<el-table-column
					v-for="(item,ind) in columnList"
					:key="item.key+ind"
					:label="item.title"
					:width="item.width"
				>
					<template slot-scope="scope">
            <div v-if="judgeImg(scope.row[item.key])">
              <el-image
                style="width: 60px; height: 60px"
                :src="scope.row[item.key]"
                fit="contain"
                :preview-src-list="[scope.row[item.key]]"></el-image>
            </div>
            <div v-else-if="(''+scope.row[item.key]).length>15">
              <el-tooltip class="item" effect="dark" :content="scope.row[item.key]" placement="top">
                <span>{{(''+scope.row[item.key]).substr(0,15) + '...'}}</span>
              </el-tooltip>
            </div>
            <div v-else>
              {{scope.row[item.key]}}
            </div>
					</template>
				</el-table-column>

				<el-table-column
					label="操作"
					width="160"
					align="center"
				>
					<template slot-scope="scope">
            <el-dropdown v-if="detailSheetUuid" @command="handleExcelCommand($event, scope.row)">
              <span class="operation-primary el-dropdown-link">Excel</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="info">预览</el-dropdown-item>
                <el-dropdown-item command="export" divided>导出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
						<span
							class="operation-primary"
							@click="openDetailModal(scope.row)"
						>详情</span>
						<span
							class="operation-primary"
							@click="openEditModal(scope.row)"
						>编辑</span>
<!--            <span
              class="operation-primary"
              @click="exportExcel(scope.row)"
            >导出</span>-->
						<span
							class="operation-del"
							@click="confirmDel(scope.row)"
						>删除</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="pagination">
			<el-pagination
				layout="total, sizes, prev, pager, next, jumper"
				:page-size="15"
				:current-page="page"
				:total="total"
				@current-change="changePage"
			>
			</el-pagination>
		</div>

		<el-dialog
			:title="moduleName+ formModeTitle[formMode]"
			:visible.sync="dialogVisible"
      top="3%"
			width="80%"
			custom-class="common-dialog"
		>
      <div v-if="['add','edit'].includes(formMode)" class="dialog-main">
        <Store
          ref="handleParser"
          v-if="dialogVisible && moduleUuid"
          :module-uuid="moduleUuid"
          :data-id="dataId"
          @on-submit="handleSubmit"
          @on-cancel="handleCancel"
          :formFillData="formFillData"
        >
        </Store>
      </div>
      <div v-else class="dialog-main">
        <Detail
          v-if="dialogVisible && moduleUuid"
          :data-id="dataId"
          :module-uuid="moduleUuid"
          :formFillData="formFillData"
        >
        </Detail>
      </div>
      <div slot="footer" v-if="['add','edit'].includes(formMode)" style="text-align: right">
        <el-button type="default" @click="dialogVisible = false" style="margin-right: 10px;">取消</el-button>
        <el-button type="primary" @click="triggerSubmit">提交</el-button>
      </div>
		</el-dialog>

    <!-- 导出 -->
    <Spreadsheet
      v-if="showSheet && sheetFormData"
      :formData="sheetFormData"
      :id="sheetUuid"
      :isSave="false"
      :isHidden="true"
      @exportSuccess="onExportSuccess"
      :autoDownLoad="'excel'"
    ></Spreadsheet>

    <!-- 导出 & 预览 -->
    <el-dialog
      custom-class="form-bind-excel-dialog"
      title="预览"
      :visible.sync="showSheetEntry"
      fullscreen
    >
      <SheetEntry
        v-if="showSheetEntry"
        :loadType="'create'"
        :type="sheetAction"
        :excelUuid="sheetUuid"
        :excelFormData="sheetFormData"
        :excelMetaData="{}"
        :solveType="solveType"
      ></SheetEntry>
    </el-dialog>
    <SheetEntry
        v-if="sheetAction === 'export'"
        :type="'export'"
        :excelUuid="sheetUuid"
        :excelFormData="sheetFormData"
        :excelMetaData="{}"
        :exportType="'excel'"
        :solveType="solveType"
        @exportSuccess="sheetEntryExportSuccess"
      ></SheetEntry>
	</div>
</template>

<script>
import { formDesign } from '@/apis/data/workflow';
// import ItemText from './components/ItemText';
import InputItem from '@/custom-component/form/newParser/InputItem';
import Store from './components/Store';
import Detail from './components/Detail';
import {Button, Col, Form, FormItem, Pagination, Row, Table, TableColumn, Tooltip, Image} from 'element-ui';
import Spreadsheet from '@/custom-component/common/Spreadsheet';
import {formValidate} from "@/custom-component/form/newParser/scripts/itemDataCopy";
import SheetEntry from '@/custom-component/common/Entry';

export default {
	name: 'index',
	components: {
		// ItemText,
		InputItem,
		Store,
		Detail,
    Spreadsheet,
		'el-image': Image,
		'el-tooltip': Tooltip,
		'el-pagination': Pagination,
    'el-table':Table,
    'el-table-column': TableColumn,
    'el-button': Button,
    'el-row': Row,
    'el-col': Col,
    'el-form': Form,
    'el-form-item': FormItem,
    SheetEntry
	},
	props: {
		// 绑定的表单UUID
		moduleUuid: {
			type: String,
			required: true,
			default: 'formModule62622152d857b'
		},
		// 表单填充数据
		formFillData: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	data() {
		return {
			showSearch: false,
			tableData: [],

			loading: true,
			dialogVisible: false,
			formMode: 'add',
			formModeTitle: {
				add: '新增',
				edit: '修改',
				detail: '详情'
			},
			dataId: 0,
			moduleName: '表单列表数据',
			formDesignData: null,
      initFormDesign: null,
			searchColumn: [],
			columnList: [],
			fieldsList: [],
			formValidate: {
        items:[]
      },
			page: 1,
			total: 0,
      // excel导出
      showSheet:false,
      sheetFormData:null,
      sheetUuid:'',
      // excel导出(ttheitao)
      listSheetConfig: null,
      detailSheetConfig: null,
      listSheetUuid: '',
      detailSheetUuid: '',
      sheetAction: '',
      showSheetEntry: false,
      solveType: "",
		};
	},
  provide(){
    return {
      parser: this,
    }
  },
	created() {
		this.initFormData();
	},
	computed: {
    judgeImg(){
      return function (data){
        data += ''
        if(
          data.indexOf('.jpg') > -1  || data.indexOf('.png') > -1 ||
          data.indexOf('.gif') > -1 || data.indexOf('.jpeg') > -1
        ){
          return true
        }
        return false
      }
    },
		columnConfig() {
			return function (key) {
				if (this.formValidate && this.formValidate.items) {
					const config = this.formValidate.items.filter(
						(item) => item.field === key
					);
					const configData = config[0] ? config[0].config : {};
					return configData;
				}
				return {};
			};
		},
		searchComp() {
			if (this.formValidate && this.formValidate.items) {
				let components = [];
				const searchList = Array.from(this.searchColumn, (item) => item.key);

				for (let item of this.formValidate.items) {
					if (searchList.includes(item.field)) {
						components.push(item);
					}
					if (item.value instanceof Array) {
						for (let itemValue of item.value) {
							if (searchList.includes(itemValue.field)) {
								components.push(itemValue);
							}
              /*for (let ele of itemValue) {
                if (searchList.includes(item.field)) {
                  components.push(ele)
                }
              }*/
						}
					}
				}
				components = JSON.parse(JSON.stringify(components));
				return components;
			}
			return null;
		}
	},
	methods: {
    handleBack() {
      this.$router.go(-1)
    },
		initFormData() {
			let param = { module_uuid: this.moduleUuid };
			formDesign.formDataList(param).then((res) => {
				if (res.data.code === 200) {
					const formDesign = res.data.data.design;
					let designData = JSON.parse(formDesign.json);
					if(designData){
						designData.need_flow = !!formDesign.need_flow;
						this.formDesignData = designData;
          } else {
						this.$message.error('表单设计为空,请配置表单后使用');
          }
					this.moduleName = formDesign.module_name;
					this.columnList = res.data.data.columns;
					this.searchColumn = res.data.data.searchs;
					this.tableData = res.data.data.data;
          this.sheetUuid = res.data.data.sheet_uuid[0] || '';

          this.listSheetConfig = res.data.data.list_sheet_uuid;
          this.detailSheetConfig = res.data.data.detail_sheet_uuid;
          this.listSheetUuid = this.listSheetConfig?.uuid || '';
          this.detailSheetUuid = this.detailSheetConfig?.uuid || '';

					this.page = res.data.data.current_page;
					this.total = res.data.data.total;

          this.formValidateData();
					this.loading = false;
				}
			});
		},
		formValidateData() {
      if(this.formDesignData?.fields){
        const data = formValidate(this.formDesignData.fields,{},{},{});
        this.formValidate.items = data;
      }
		},
		openAddModal() {
			this.dialogVisible = true;
			this.formMode = 'add';
			this.dataId = 0;
		},
		openEditModal(row) {
			this.dialogVisible = true;
			this.formMode = 'edit';
			this.dataId = row.id;
		},
		openDetailModal(row) {
			this.dialogVisible = true;
			this.formMode = 'detail';
			this.dataId = row.id;
		},
    exportExcel(row){
      if(!this.sheetUuid){
        this.$message.error('请配置导出模板');
        return false;
      }
      this.$loading({
        text: '导出中....'
      });
      formDesign.formDataExport(row.id).then((res) => {
        if (res.data.code === 200) {
          this.sheetFormData = {
            [this.moduleUuid]: res.data.data
          }
          this.showSheet = true;
        } else {
          this.$message.success(res.data.msg);
        }
      });
    },
    onExportSuccess(){
      this.$loading().close();
      this.showSheet = false;
      this.$message.success('导出成功！');
    },
		confirmDel(row) {
			this.$confirm('请确认是否要删除此条数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.loading = true;
					formDesign.formDataDel(row.id).then((res) => {
						if (res.data.code === 200) {
							this.$message.success(res.data.msg);
							this.searchData();
						} else {
							this.$message.success(res.data.msg);
						}
						this.loading = false;
					});
				})
				.catch(() => {});
		},
		handleCancel() {
			this.dialogVisible = false;
		},
    triggerSubmit(){
      this.$refs.handleParser.triggerSubmit()
    },
		handleSubmit(formSubmitData, dataId) {
      console.log(formSubmitData,dataId,'1111111111111111111')
			formSubmitData.module_uuid = this.moduleUuid;
			if (dataId) {
				formDesign.formDataUpdate(formSubmitData, dataId).then((res) => {
					if (res.data.code === 200) {
						this.$message.success(res.data.msg);
						this.searchData();
						this.dialogVisible = false;
					} else {
						this.$message.success(res.data.msg);
					}
				});
			} else {
				formDesign.formDataStore(formSubmitData).then((res) => {
					if (res.data.code === 200) {
						this.$message.success(res.data.msg);
						this.searchData();
						this.dialogVisible = false;
					} else {
						this.$message.success(res.data.msg);
					}
				});
			}
		},
		changePage(page) {
			this.searchData({ page });
		},
		searchData(param = {}) {
			param.module_uuid = this.moduleUuid;
			formDesign.formDataList(param).then((res) => {
				if (res.data.code === 200) {
					this.tableData = res.data.data.data;

					this.page = res.data.data.current_page;
					this.total = res.data.data.total;
				}
			});
		},
		searchRest() {
			this.searchData();
		},
		searchAction() {
			const searches = this.searchComp;
			let formSubmitData = {};
			if (searches && searches instanceof Array) {
				for (let item of searches) {
					if (item.value) {
						formSubmitData[item.field] = item.value;
					}
				}
			}
			const keys = Object.keys(formSubmitData);
			if (keys.length > 0) {
				let params = { search: formSubmitData };
				this.searchData(params);
			}
			this.showSearch = false;
		},
    handleExcelCommand(command, row){
      this.$loading();
      this.sheetUuid = this.detailSheetUuid;
      this.solveType = this.detailSheetConfig.config.solveType;

      if(command == 'info'){
        this.sheetAction = 'info';

        formDesign.formDataDetailWithMonitor({
          module_uuid:this.moduleUuid,
          id:row.id
        }).then((res)=>{
          if(res.data.code === 200){
            this.sheetFormData = {
              [this.moduleUuid]: res.data.data
            }

            this.showSheetEntry = true;
          }

          this.$loading().close();
        })
        .catch((err)=>{
          this.sheetAction = "";
          this.$loading().close();
          console.log(err);
        });
      }else{
        formDesign.formDataDetailWithMonitor({
          module_uuid:this.moduleUuid,
          id:row.id
        }).then((res)=>{
          if(res.data.code === 200){
            this.sheetFormData = {
              [this.moduleUuid]: res.data.data
            }
            this.sheetAction = "export";
          }
        })
        .catch((err)=>{
          this.$loading().close();
          this.sheetAction = "";
          console.log(err);
        });
      }
    },
    handleListExport(){
      this.$loading();
      this.sheetUuid = this.listSheetUuid;
      this.solveType = this.listSheetConfig.config.solveType;

      formDesign.formDataListWithMonitor({
        module_uuid:this.moduleUuid,
        size: 10000,
      }).then((res)=>{
        if(res.data.code === 200){
          this.sheetFormData = {
            [this.moduleUuid]: res.data.data
          }

          this.sheetAction = "export";
        }
      })
      .catch((err)=>{
        this.sheetAction = "";
        this.$loading().close();
        console.log(err);
      });
    },
    sheetEntryExportSuccess(){
      this.$loading().close();
    }
	}
};
</script>

<style lang="less" scoped>
:deep(.common-dialog)  {
  .el-dialog__body{
    padding: 0;
  }
}

.dialog-main{
  padding: 0 15px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.form-data-list {
	position: relative;

	.wrapper {
		max-height: 90vh;
		overflow-x: hidden;
		overflow-y: auto;
		padding-right: 10px;
	}

	.header {
		display: flex;
		align-items: center;
		height: 56px;
		padding: 0 15px;
		border-bottom: 1px solid #ebeef5;

		.form-title {
			width: 70%;
		}

		.action-bar {
			flex: 1;
			text-align: right;

			i {
				padding: 10px;
			}
		}
	}

  .search-container{
    position: absolute;
    z-index: 10;
    width: 100%;
    background-color: #ffffff;
    box-shadow:-5px 5px 10px -4px #ededed,5px 5px 10px -4px #ededed;

    .search {
      position: relative;
      margin-top: 10px;
      padding: 0 15px 10px;
      border-bottom: 1px solid #ebeef5;

      :deep(.el-select) {
        width: 100%;
      }

      .search-btn {
        position: absolute;
        bottom: 20px;
        right: 15px;
      }

      :deep(.search-comp) {
        padding-right: 170px;

        .el-form-item {
          margin-bottom: 10px !important;
        }

        .el-form-item__label {
          line-height: 26px;
          padding: 0;
        }
      }
    }
  }

	.table-list {
		margin-top: 15px;
		padding: 0 15px;
    height: calc(100vh - 265px);

		.operation-primary {
			padding: 0 5px;
			color: var(--themeColor);
			position: relative;
			cursor: pointer;
			font-size: 12px;
		}

		.operation-primary:not(:last-child):after {
			content: '';
			height: 12px;
			width: 1px;
			background-color: #c2c5cc;
			position: absolute;
			top: 3px;
			right: 0;
		}

		.operation-del {
			font-size: 12px;
      padding-left: 8px;
      color: red;
      cursor: pointer;
		}
	}

	.pagination {
    margin-top: 10px;
    margin-right: 5px;
    text-align: left;
	}
}
</style>

<style lang="less">
.form-bind-excel-dialog {
  display: flex;
  flex-direction: column;

  .el-dialog__body {
    flex: 1;
  }
}
</style>
